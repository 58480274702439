import SlideUp from '../../common/utils/slideUp';
import dynamic from 'next/dynamic';
import { Homepage } from '@/typings/types';

const StoreRenderer = dynamic(() => import('./storeRenderer'));

type LocationsProps = {
  locations: Homepage['locations'];
};

const LocationWrapper = ({ locations }: LocationsProps) => {
  return (
    <div
      className={'bg-fadeWhite mx-auto flex w-full flex-col items-center justify-center lg:w-full'}
    >
      <div className={'flex w-11/12 flex-col items-center justify-center lg:w-full'}>
        <SlideUp className={'pb-12'} yOffset={-10}>
          <h2
            className={
              'p-2 text-center font-stigsaMedium text-5xl font-medium uppercase text-chocolate'
            }
          >
            {locations.title}
          </h2>
        </SlideUp>
      </div>
      {locations?.stores && <StoreRenderer stores={locations?.stores} />}
    </div>
  );
};

export default LocationWrapper;
