import { animated, useSpring } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';

type IProps = {
  children?: React.ReactNode;
  className?: string;
  delay?: boolean;
  delayDuration?: number;
  duration?: number;
};

const FadeIn = ({ children, className, delay, duration, delayDuration }: IProps) => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.4,
    triggerOnce: true,
  });

  const delayFn = (delayDuration?: number, delay?: boolean) => {
    if (delay) {
      return delayDuration ?? 200;
    }
    return 0;
  };

  const fadeIn = useSpring({
    from: { opacity: 0 },
    to: {
      opacity: inView ? 1 : 0,
      duration: duration ?? 700,
    },
    delay: delayFn(delayDuration, delay),
  });
  // use a custom tag for the animated dom
  return (
    <animated.div ref={ref} className={className} style={fadeIn}>
      {children}
    </animated.div>
  );
};

export default FadeIn;
