import FooterContentRenderer from './contentRenderer';
import FooterBackgroundImage from './footerBackgroundImage';
import { Homepage } from '@/typings/types';

type IProps = {
  footer: Homepage['footer'];
};

const FooterWrapper = ({ footer }: IProps) => {
  return (
    <footer className={'w-full'}>
      <FooterBackgroundImage backgroundImage={footer.backgroundImage} />
      <FooterContentRenderer />
    </footer>
  );
};

export default FooterWrapper;
