import { ReactNode } from 'react';

type LiProps = {
  children: ReactNode;
};

export const Li = ({ children }: LiProps) => {
  return (
    <li className={"last:after:mx-0 last:after:content-[''] lg:after:mx-2 lg:after:content-['|']"}>
      {children}
    </li>
  );
};
