export const ArrowSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="11" fill="none">
      <g clipPath="url(#a)">
        <path
          fill="#fff"
          d="M13 5.5l-.456-.492L7.914 0 6.91.984l3.516 3.8H0v1.432h10.425l-3.516 3.8L7.915 11l4.63-5.008L13 5.5z"
        ></path>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h13v11H0z" transform="rotate(180 6.5 5.5)"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
