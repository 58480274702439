import { ArrowSvg } from '../svgs/arrowSvg';
import Link from 'next/link';

type InternalCtaProps = {
  backgroundColor: string;
  textColor: string;
  link: string;
  text: string;
  className?: string;
};

type ExternalCtaProps = InternalCtaProps & {
  arrow?: boolean;
};

export const InternalCta = ({
  backgroundColor,
  textColor,
  link,
  text,
  className,
}: InternalCtaProps) => {
  return (
    link && (
      <Link
        className={`rounded-full py-2 px-6 font-inter text-base font-medium ${textColor} ${className} ${backgroundColor}`}
        href={link}
      >
        {text}
      </Link>
    )
  );
};

export const ExternalCta = ({
  backgroundColor,
  textColor,
  link,
  text,
  className,
  arrow,
}: ExternalCtaProps) => {
  return (
    <a
      href={link}
      className={`flex w-fit min-w-fit items-center rounded-full py-2 px-6 font-inter text-base font-medium ${textColor} ${className} ${backgroundColor}`}
      target={'_blank'}
      rel={'noreferrer'}
    >
      {arrow && <ArrowSvg />}
      <span className={arrow ? 'pl-1.5' : ''}>{text}</span>
    </a>
  );
};
