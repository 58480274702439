import SlideUp from '../../common/utils/slideUp';
import Markdown from 'marked-react';
import { Homepage } from '@/typings/types';

type OllcaDescriptionProps = {
  ollcaDescription: Homepage['ollcaDescription'];
};

const OllcaDescription = ({ ollcaDescription }: OllcaDescriptionProps) => {
  return (
    <div className={'mx-auto flex flex-col justify-center lg:px-3'}>
      <SlideUp yOffset={-10}>
        <h2
          className={
            'pb-4 font-stigsaMedium text-[1.5625rem] uppercase leading-7 tracking-wide text-chocolate'
          }
        >
          {ollcaDescription.title}
        </h2>
      </SlideUp>
      <SlideUp
        delay
        yOffset={-5}
        className={
          'flex w-11/12 flex-col items-center justify-center font-libertineRegular text-lg prose-p:leading-6 prose-p:text-chocolate prose-strong:font-bold lg:w-9/12'
        }
      >
        <Markdown>{ollcaDescription.content}</Markdown>
      </SlideUp>
    </div>
  );
};

export default OllcaDescription;
