import { Li } from './list';
import bigLogo from '@/public/big-logo.svg';
import facebook from '@/public/facebook.svg';
import instagram from '@/public/instagram.svg';
import Image from 'next/image';
import Link from 'next/link';

const FooterContentRenderer = () => {
  return (
    <div className={'relative flex flex-col items-center justify-center bg-chocolate'}>
      <div className={'absolute -top-36 z-20'}>
        <Image src={bigLogo} alt={'logo'} />
      </div>
      <ul
        className={
          'mb-16 flex w-10/12 max-w-6xl flex-col justify-between pt-64 lg:w-full lg:flex-row'
        }
      >
        <li
          className={'mb-14 flex justify-center border-b border-dacquoise lg:mx-4 lg:mb-0 lg:w-1/2'}
        >
          <Image src={facebook} className={'mb-3'} alt={'facebook'} />
        </li>
        <li className={'flex justify-center border-b border-dacquoise lg:mx-4 lg:w-1/2'}>
          <Image src={instagram} className={'mb-3'} alt={'instagram'} />
        </li>
      </ul>
      <ul
        className={
          'flex w-11/12 flex-col justify-center space-y-4 pb-6 text-center font-inter text-xs font-normal text-white lg:w-full lg:flex-row lg:space-y-0 lg:text-left'
        }
      >
        <Li>Ma Boulangerie par Christophe Cressent © {new Date().getFullYear()}</Li>
        <Li>
          <Link href={'/mentions-legales'} className={'underline underline-offset-4'}>
            Mentions légales
          </Link>
        </Li>
        <Li>
          Design{' '}
          <a
            href={'https://sarahkugel.com/'}
            className={'underline underline-offset-4'}
            target={'_blank'}
            rel={'noreferrer noopener'}
          >
            Sarah Kügel
          </a>
        </Li>
        <Li>
          Réalisation{' '}
          <a
            href={'https://www.gaylordjulien.dev/'}
            className={'underline underline-offset-4'}
            target={'_blank'}
            rel={'noreferrer noopener'}
          >
            Gaylord Julien
          </a>
        </Li>
      </ul>
    </div>
  );
};

export default FooterContentRenderer;
