import Image from 'next/image';
import { Homepage } from '@/typings/types';

type OllcaImageProps = {
  ollcaImage: Homepage['ollcaImage'];
};

const OllcaImage = ({ ollcaImage }: OllcaImageProps) => {
  const { url, alternativeText, placeholder, width, height } =
    ollcaImage?.image?.data?.attributes ?? {};
  return (
    <div className={'relative flex items-center justify-center self-center py-14 lg:pl-5'}>
      {url && (
        <Image
          src={`${process.env.NEXT_PUBLIC_STRAPI_URL}${url}`}
          width={width ?? 0}
          height={height ?? 0}
          alt={alternativeText ?? ''}
          placeholder={placeholder ? 'blur' : 'empty'}
          blurDataURL={placeholder ?? ''}
        />
      )}
    </div>
  );
};

export default OllcaImage;
