import { ExternalCta } from '@/common/ctas';
import FadeIn from '../../common/utils/fadeIn';
import SlideUp from '../../common/utils/slideUp';
import { Homepage } from '@/typings/types';

type OllcaLinksProps = {
  ollcaLinks: Homepage['ollcaLinks'];
};

const OllcaLinks = ({ ollcaLinks }: OllcaLinksProps) => {
  return (
    <FadeIn className={'flex flex-col justify-start'}>
      <SlideUp delay yOffset={-10}>
        <h2
          className={
            'pb-4 font-stigsaMedium text-[1.5625rem] uppercase leading-7 tracking-wide text-chocolate'
          }
        >
          Je souhaite <span className={'block'}>commander en ligne :</span>
        </h2>
      </SlideUp>
      {ollcaLinks.map((item, index) => (
        <SlideUp
          delay
          yOffset={-5}
          delayDuration={index * 100}
          key={item?.id}
          className={
            'flex w-11/12 flex-col font-libertineRegular text-lg prose-p:text-chocolate  prose-strong:font-bold lg:w-9/12'
          }
        >
          {item?.url && item?.text && (
            <ExternalCta
              backgroundColor={'bg-chocolate'}
              textColor={'text-white'}
              link={item?.url}
              text={item?.text}
              arrow
            />
          )}
        </SlideUp>
      ))}
    </FadeIn>
  );
};

export default OllcaLinks;
