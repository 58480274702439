import { animated, useSpring } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';

type ExpandAndRetractYProps = {
  className?: string;
  duration?: number;
  children?: React.ReactNode;
};

const ExpandAndRetractY = ({ className, duration, children }: ExpandAndRetractYProps) => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.4,
    triggerOnce: true,
  });
  const expandAndRetract = useSpring({
    from: { transform: 'scaleY(1)', bgColor: 'transparent' },
    to: {
      transform: inView ? `scaleY(0)` : 'scaleY(1)',
      transformOrigin: '0% 100%',
      bgColor: inView ?? '#956C58',
    },
    config: {
      duration: duration ?? 1250,
    },
  });
  return (
    <animated.div ref={ref} className={className} style={expandAndRetract} aria-hidden>
      {children}
    </animated.div>
  );
};

export default ExpandAndRetractY;
