import ExpandAndRetractY from '../../common/utils/expandAndReactY';
import Image from 'next/image';
import { Fragment } from 'react';
import { Homepage } from '@/typings/types';

type FooterBackgroundImageProps = {
  backgroundImage: Homepage['footer']['backgroundImage'];
};

const FooterBackgroundImage = ({ backgroundImage }: FooterBackgroundImageProps) => {
  const { url, alternativeText, placeholder } = backgroundImage?.data?.attributes ?? {};
  return (
    <Fragment>
      <ExpandAndRetractY
        duration={1000}
        className={'absolute z-20 h-[70vh] w-full bg-chocolate'}
      ></ExpandAndRetractY>
      <div className={'relative z-10 h-[70vh] w-full'}>
        {url && (
          <Image
            src={`${process.env.NEXT_PUBLIC_STRAPI_URL}${url}`}
            alt={alternativeText ?? ''}
            placeholder={placeholder ? 'blur' : 'empty'}
            blurDataURL={placeholder ?? ''}
            fill
            className={'object-cover'}
          />
        )}
      </div>
    </Fragment>
  );
};

export default FooterBackgroundImage;
