import Head from 'next/head';
import { useRouter } from 'next/router';
import { ComponentSharedSeo, Maybe } from '@/typings/types';

const Seo: React.FC<{ seo: Maybe<ComponentSharedSeo> | undefined }> = ({ seo }) => {
  const router = useRouter();

  // Get the production url
  const prodUrl = process.env.NEXT_PUBLIC_PROD_URL;

  // Get the meta tags
  const meta = {
    title: seo?.metaTitle,
    description: seo?.metaDescription,
    url: prodUrl,
    image: `${process.env.api}${seo?.metaImage?.data?.attributes?.url}`,
    image_alt: seo?.metaImage?.data?.attributes?.alternativeText,
    type: 'website',
    robots: 'follow, homepage',
    ...seo,
  };

  return (
    <Head>
      {/* Default meta tags */}
      <title>{meta.title}</title>
      <meta name="robots" content={meta.robots} />
      <meta content={meta.description} name="description" />
      <meta property="og:url" content={`${meta.url}${router.asPath}`} />
      <link rel="canonical" href={`${meta.url}${router.asPath}`} />
      <meta property="og:type" content={meta.type} />
      <meta property="og:description" content={meta.description} />
      <meta property="og:title" content={meta.title} />

      <meta name="image" property="og:image" content={`${process.env.api}${meta.image}`} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={meta.title} />
      <meta name="twitter:description" content={meta.description} />
      <meta name="twitter:image" content={`${process.env.api}${meta.image}`} />
    </Head>
  );
};

export default Seo;
