import SlideUp from '../../common/utils/slideUp';
import OllcaDescription from './ollcaDescription';
import OllcaImage from './ollcaImage';
import OllcaLinks from './ollcaLinks';
import { Homepage } from '@/typings/types';

type OllcaWrapperProps = {
  ollcaImage: Homepage['ollcaImage'];
  ollcaDescription: Homepage['ollcaDescription'];
  ollcaLinks: Homepage['ollcaLinks'];
};

const OllcaWrapper = ({ ollcaImage, ollcaDescription, ollcaLinks }: OllcaWrapperProps) => {
  return (
    <section
      className={
        'mx-auto grid w-9/12  max-w-[330px] gap-y-8 py-16 md:max-w-max md:grid-flow-row md:grid-cols-2 md:gap-x-8 lg:w-auto lg:grid-flow-row lg:grid-cols-3 lg:gap-y-0'
      }
    >
      <SlideUp yOffset={-10}>
        <OllcaImage ollcaImage={ollcaImage} />
      </SlideUp>
      <OllcaDescription ollcaDescription={ollcaDescription} />
      <OllcaLinks ollcaLinks={ollcaLinks} />
    </section>
  );
};

export default OllcaWrapper;
